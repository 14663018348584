import styled from "styled-components";
import { Text as TextComponent } from "@ifgengineering/component-library";
import { theme } from "@styles/theme";

export const ShowMoreButton = styled.button`
  color: ${theme.colors.SLATE800};
  font-weight: 500;
  text-decoration-color: ${theme.colors.SLATE800};
  text-decoration: underline;
  margin: 1em;
`;

export const LinksContainer = styled.div`
  display: flex;
  gap: 24px;
  padding-top: 20px;
`;

export const Layout = styled.div`
  padding-top: 5vh;
  width: 100%;
  min-height: 70vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const InnerWrapper = styled.div`
  max-width: 850px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  position: relative;

  @media (max-width: 900px) {
    width: 100%;
    padding: 1rem;
  }
`;

export const ButtonWrapper = styled.div`
  width: 327px;
  display: flex;
  flex-direction: column;
`;

export const HelpText = styled.div`
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  text-align: left;
  width: fit-content;
  margin: 0 0 2rem 0;
  color: ${theme.colors.SLATE800};
`;

export const Description = styled.p`
  margin: 0 0 0.3rem 0;
  color: ${theme.colors.SLATE700};

  & a {
    color: ${theme.colors.SLATE800};
    font-weight: 500;
  }
`;

export const CurrencyDescription = styled(TextComponent)`
  margin: 0 0 1rem 0;
  color: ${theme.colors.SLATE900};
`;

export const HeaderText = styled.h2<{ align?: string }>`
  width: 100%;
  font-weight: bold;
  color: ${theme.colors.SLATE900};
  text-align: ${({ align }) => align || "left"};
  margin: 2rem 0 1rem 0;
  font-size: 1.5rem;
  text-transform: capitalize;

  @media (max-width: 900px) {
    margin: 0 0 1rem 0;
  }
`;

export const CardsContainer = styled.div`
  width: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 327px;
  flex-direction: column;
  gap: 1.4rem;
  margin-bottom: 2rem;
`;

export const Spacer = styled.div`
  margin-bottom: 2rem;
  width: 100%;
`;

export const ErrorMessage = styled.div`
  color: ${theme.colors.ERROR800};
  font-size: 0.75rem;
  margin-bottom: 1rem;
`;

export const CharitiesContainer = styled.div`
  gap: 1rem;
  color: ${theme.colors.SLATE900};
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  max-width: 530px;
`;

export const TwoColumns = styled.div`
  display: flex;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 30px;
`;

export const SecondColumn = styled.div`
  display: flex;
  min-width: 460px;
  @media (max-width: 900px) {
    min-width: 100%;
  }
`;
